// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("/opt/build/repo/src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-zh-js": () => import("/opt/build/repo/src/pages/about.zh.js" /* webpackChunkName: "component---src-pages-about-zh-js" */),
  "component---src-pages-contact-en-js": () => import("/opt/build/repo/src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-zh-js": () => import("/opt/build/repo/src/pages/contact.zh.js" /* webpackChunkName: "component---src-pages-contact-zh-js" */),
  "component---src-pages-impact-en-js": () => import("/opt/build/repo/src/pages/impact.en.js" /* webpackChunkName: "component---src-pages-impact-en-js" */),
  "component---src-pages-impact-zh-js": () => import("/opt/build/repo/src/pages/impact.zh.js" /* webpackChunkName: "component---src-pages-impact-zh-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-zh-js": () => import("/opt/build/repo/src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-locations-en-js": () => import("/opt/build/repo/src/pages/locations.en.js" /* webpackChunkName: "component---src-pages-locations-en-js" */),
  "component---src-pages-locations-zh-js": () => import("/opt/build/repo/src/pages/locations.zh.js" /* webpackChunkName: "component---src-pages-locations-zh-js" */),
  "component---src-pages-locations-cnpc-wuzhong-road-en-js": () => import("/opt/build/repo/src/pages/locations/cnpc-wuzhong-road.en.js" /* webpackChunkName: "component---src-pages-locations-cnpc-wuzhong-road-en-js" */),
  "component---src-pages-locations-cnpc-wuzhong-road-zh-js": () => import("/opt/build/repo/src/pages/locations/cnpc-wuzhong-road.zh.js" /* webpackChunkName: "component---src-pages-locations-cnpc-wuzhong-road-zh-js" */),
  "component---src-pages-locations-intime-hangzhou-en-js": () => import("/opt/build/repo/src/pages/locations/intime-hangzhou.en.js" /* webpackChunkName: "component---src-pages-locations-intime-hangzhou-en-js" */),
  "component---src-pages-locations-intime-hangzhou-zh-js": () => import("/opt/build/repo/src/pages/locations/intime-hangzhou.zh.js" /* webpackChunkName: "component---src-pages-locations-intime-hangzhou-zh-js" */),
  "component---src-pages-locations-k-11-art-mall-en-js": () => import("/opt/build/repo/src/pages/locations/k11-art-mall.en.js" /* webpackChunkName: "component---src-pages-locations-k-11-art-mall-en-js" */),
  "component---src-pages-locations-k-11-art-mall-zh-js": () => import("/opt/build/repo/src/pages/locations/k11-art-mall.zh.js" /* webpackChunkName: "component---src-pages-locations-k-11-art-mall-zh-js" */),
  "component---src-pages-locations-lilacs-international-commercial-center-en-js": () => import("/opt/build/repo/src/pages/locations/lilacs-international-commercial-center.en.js" /* webpackChunkName: "component---src-pages-locations-lilacs-international-commercial-center-en-js" */),
  "component---src-pages-locations-lilacs-international-commercial-center-zh-js": () => import("/opt/build/repo/src/pages/locations/lilacs-international-commercial-center.zh.js" /* webpackChunkName: "component---src-pages-locations-lilacs-international-commercial-center-zh-js" */),
  "component---src-pages-locations-peoples-square-en-js": () => import("/opt/build/repo/src/pages/locations/peoples-square.en.js" /* webpackChunkName: "component---src-pages-locations-peoples-square-en-js" */),
  "component---src-pages-locations-peoples-square-zh-js": () => import("/opt/build/repo/src/pages/locations/peoples-square.zh.js" /* webpackChunkName: "component---src-pages-locations-peoples-square-zh-js" */),
  "component---src-pages-locations-shanghai-hongqiao-international-airport-en-js": () => import("/opt/build/repo/src/pages/locations/shanghai-hongqiao-international-airport.en.js" /* webpackChunkName: "component---src-pages-locations-shanghai-hongqiao-international-airport-en-js" */),
  "component---src-pages-locations-shanghai-hongqiao-international-airport-zh-js": () => import("/opt/build/repo/src/pages/locations/shanghai-hongqiao-international-airport.zh.js" /* webpackChunkName: "component---src-pages-locations-shanghai-hongqiao-international-airport-zh-js" */),
  "component---src-pages-locations-shanghai-international-commerce-center-en-js": () => import("/opt/build/repo/src/pages/locations/shanghai-international-commerce-center.en.js" /* webpackChunkName: "component---src-pages-locations-shanghai-international-commerce-center-en-js" */),
  "component---src-pages-locations-shanghai-international-commerce-center-zh-js": () => import("/opt/build/repo/src/pages/locations/shanghai-international-commerce-center.zh.js" /* webpackChunkName: "component---src-pages-locations-shanghai-international-commerce-center-zh-js" */),
  "component---src-pages-locations-shanghai-pudong-international-airport-en-js": () => import("/opt/build/repo/src/pages/locations/shanghai-pudong-international-airport.en.js" /* webpackChunkName: "component---src-pages-locations-shanghai-pudong-international-airport-en-js" */),
  "component---src-pages-locations-shanghai-pudong-international-airport-zh-js": () => import("/opt/build/repo/src/pages/locations/shanghai-pudong-international-airport.zh.js" /* webpackChunkName: "component---src-pages-locations-shanghai-pudong-international-airport-zh-js" */),
  "component---src-pages-locations-sinopec-jinxiu-road-en-js": () => import("/opt/build/repo/src/pages/locations/sinopec-jinxiu-road.en.js" /* webpackChunkName: "component---src-pages-locations-sinopec-jinxiu-road-en-js" */),
  "component---src-pages-locations-sinopec-jinxiu-road-zh-js": () => import("/opt/build/repo/src/pages/locations/sinopec-jinxiu-road.zh.js" /* webpackChunkName: "component---src-pages-locations-sinopec-jinxiu-road-zh-js" */),
  "component---src-pages-locations-unielite-shopping-mall-en-js": () => import("/opt/build/repo/src/pages/locations/unielite-shopping-mall.en.js" /* webpackChunkName: "component---src-pages-locations-unielite-shopping-mall-en-js" */),
  "component---src-pages-locations-unielite-shopping-mall-zh-js": () => import("/opt/build/repo/src/pages/locations/unielite-shopping-mall.zh.js" /* webpackChunkName: "component---src-pages-locations-unielite-shopping-mall-zh-js" */),
  "component---src-pages-locations-xtd-plaza-en-js": () => import("/opt/build/repo/src/pages/locations/xtd-plaza.en.js" /* webpackChunkName: "component---src-pages-locations-xtd-plaza-en-js" */),
  "component---src-pages-locations-xtd-plaza-zh-js": () => import("/opt/build/repo/src/pages/locations/xtd-plaza.zh.js" /* webpackChunkName: "component---src-pages-locations-xtd-plaza-zh-js" */),
  "component---src-pages-my-files-js": () => import("/opt/build/repo/src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-news-en-js": () => import("/opt/build/repo/src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-zh-js": () => import("/opt/build/repo/src/pages/news.zh.js" /* webpackChunkName: "component---src-pages-news-zh-js" */),
  "component---src-pages-partners-en-js": () => import("/opt/build/repo/src/pages/partners.en.js" /* webpackChunkName: "component---src-pages-partners-en-js" */),
  "component---src-pages-partners-zh-js": () => import("/opt/build/repo/src/pages/partners.zh.js" /* webpackChunkName: "component---src-pages-partners-zh-js" */),
  "component---src-pages-thank-you-zh-js": () => import("/opt/build/repo/src/pages/thank-you.zh.js" /* webpackChunkName: "component---src-pages-thank-you-zh-js" */)
}

